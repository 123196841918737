import React, { useState, useEffect, useMemo } from "react";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";

import moment from "moment";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import Navigation from "../Navigation";
import Helpers from "../Helpers";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  LinearProgress,
  NativeSelect,
  Button,
  Tabs,
  Tab,
  withStyles,
  TextField,
} from "@material-ui/core";
import CustomDataTable from "../CustomDataTable";
import Functions from "./functions";
import { DownloadComponents } from "./Download";

const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#256141",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 150,
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Poppins"'].join(","),
    "&:hover": {
      color: "#256141",
      opacity: 1,
    },
    "&:focus": {
      color: "#256141",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const List = (props) => {
  const helpers = new Helpers();
  const functions = new Functions();
  const storage = firebase.storage().ref();

  const [transactionList, setTransactionList] = useState([]);
  const [transactionListLoading, setTransactionListLoading] = useState(false);

  const [imageDialog, setImageDialog] = useState(false);
  const [openImageDialogLoading, setOpenImageDialogLoading] = useState(false);

  const [selectedTrans, setSelectedTrans] = useState({});

  const [sortdate, setSortDate] = useState({
    startDate: "",
    toDate: ""
  })

  const [tab, setTab] = useState(() => {
    const param = new URLSearchParams(props.location.search).get("tab");
    if (!param) {
      return "in";
    }
    return param === "booking" ? "in" : "out";
  });

  const [parameter, setParameter] = useState(() => {
    const param = new URLSearchParams(props.location.search).get("status");
    return !param ? [] : [param];
  });

  const columns = useMemo(() => {
    let col = [
      {
        name: "submissionDate",
        label: "Submission Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment.unix(value).format("MM/DD/YYYY h:mm a");
          },
        },
      },
      {
        name: "processedDate",
        label: "Processed Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === ""
                  ? "-"
                  : moment.unix(value.seconds).format("MM/DD/YYYY")}
              </>
            );
          },
        },
      },
      {
        name: "accountNumber",
        label: "Account Number",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "clientName",
        label: "Client Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "companyName",
        label: "Company Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "fundName",
        label: "Fund Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "fundCode",
        label: "Fund Code",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "amount",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{helpers.formatToPHP(value)}</>;
          },
        },
      },
      {
        name: "invAmount",
        label: "Investment Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{helpers.formatToPHP(value)}</>;
          },
        },
      },
      {
        name: "transId",
        label: "Transaction ID",
        options: {
          filter: false,
          sort: true,
        },
      },

      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          filterList: parameter,
        },
      },
    ];

    if (tab === "in") {
      col.push({
        name: "transactionType",
        label: "Transaction Type",
        options: {
          filter: true,
          sort: true,
        },
      });

      col.push({
        name: "transactionImage",
        label: "Transaction Image",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {[
                  "processingredemption",
                  "redeemed",
                  "declinedredemption",
                ].includes(tableMeta.rowData[10]) ? (
                  "-"
                ) : tableMeta.rowData[11] === "recurring" ? (
                  "-"
                ) : (
                  <Button
                    color="primary"
                    size="small"
                    style={{ color: "#c8932a", fontFamily: "Poppins" }}
                    onClick={() => getTransactionImage(tableMeta.rowData[9])}
                    disabled={tableMeta.rowData[12] === "recurring" || tableMeta.rowData[11] === "dividend-reinvestment"}
                  >
                    View Image
                  </Button>
                )}
              </>
            );
          },
        },
      });

      col.push({
        name: "paymentValid",
        label: "Payment Validation",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {[
                  "processingredemption",
                  "redeemed",
                  "declinedredemption",
                ].includes(tableMeta.rowData[11]) ? (
                  "-"
                ) : (
                  <FormControl variant="outlined">
                    <NativeSelect
                      onChange={(e) =>
                        setPayValidity(e.target.value, tableMeta.rowData[9])
                      }
                      value={value}
                      name="Validity"
                    >
                      <option value="pending">Pending</option>
                      <option value="validated">Validated</option>
                      <option value="invalid">Invalid</option>
                    </NativeSelect>
                  </FormControl>
                )}
              </>
            );
          },
        },
      });
    } else {
      col.splice(10, 0, {
        name: "redemptionType",
        label: "Redemption Type",
        options: {
          filter: false,
          sort: true,
        },
      });
    }

    return col;
  }, [parameter, tab, transactionList]);

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    selectableRows: false,
    download: false,
    print: false,
    sortOrder: {
      name: "submissionDate",
      direction: "asc",
    },
    // onFilterChange: (columnId, value) => setParameter(value[11]),
    rowsPerPageOptions: [50, 100, 200],
    rowsPerPage: 50,
  };

  useEffect(() => {
    getTransactions();
  }, [tab]);

  const getTransactions = async () => {
    const dateFormat = helpers.dateFormat;
    try {
      let invRef = props.firebase.db.collection("transactions");

      if (tab === "in") {
        invRef = invRef.where("status", "in", [
          "processing",
          "approved",
          "declined",
        ]);
      } else {
        invRef = invRef.where("status", "in", [
          "processingRedemption",
          "redeemed",
          "declined",
        ]);
      }

      setTransactionListLoading(true);
      const transactions = await invRef.get();


      const list = [];
      const promises = transactions.docs.map(async (doc) => {

        try {
          const datas = await props.firebase.db
            .collection("users")
            .doc(doc.data().clientDetails.userUid)
            .collection("accountDetails")
            .doc("AD")
            .get();



          const data = doc.data();
          let transObj = {
            submissionDate: data.datePlaced.seconds,
            processedDate: data.dateProcessed,
            accountNumber: data.clientDetails.userAccountNumber,
            clientName: `${data.clientDetails.userFirstName || "N/A"} ${data.clientDetails.userLastName || "N/A"
              }`,
            companyName: data.fundCompanyDetails.fundCompanyName,
            fundName: data.fundDetails.fundName,
            fundCode: data.fundDetails.fundCode,
            amount: isNaN(data.amount) ? "-" : data.amount,
            amountFormat: isNaN(data.amount) ? "-" : parseFloat(data.amount).toFixed(2),
            invAmount: parseFloat(parseFloat(data.amount) - data.fee),
            invAmountFormat: parseFloat(
              parseFloat(data.amount) - data.fee
            ).toFixed(2),
            transId: data.eventId,
            status: data.status,
            redemptionType: data.redemptionType,
            transactionImage: data.depositSlip,
            transactionImageSecondary: data.depositSlipSecondary,
            paymentValid: data.paymentValid,
            fee: data.fee,
            transactionId: doc.id,
            subDate: dateFormat(data.datePlaced.toDate().toDateString()),
            subTime: data.datePlaced.toDate().toLocaleTimeString(),
            folio: data.folio,
            settlementDetails: datas.data(),
            shares: data.shares,
            rateAtBooking: data.rateAtBooking,
            transactionType:
              data.transactionType !== undefined && data.transactionType !== ""
                ? data.transactionType === "recurring"
                  ? data.transactionType
                  : data.transactionType === "dividendReinvestment" ? "dividend-reinvestment" : "one-time"
                : "one-time",
          };

          list.push(transObj);
        } catch (error) { }
      });

      await Promise.all(promises);

      setTransactionList(list);
      setTransactionListLoading(false);
    } catch (err) {
      setTransactionListLoading(false);
      alert(err);
    }
  };

  const getTransactionImage = async (eventId) => {
    try {
      const index = transactionList.findIndex((x) => x.transId === eventId);

      if (index !== -1) {
        const transaction = transactionList[index];

        if (transaction.transactionImageSecondary !== "") {
          setSelectedTrans({ item: transaction, index });
          setImageDialog(true);
        } else {
          const imageUrl = transaction.transactionImage;
          const entryUrl = transaction.transactionUrl ?? false;

          if (entryUrl) return window.open(entryUrl);

          const imgUrl = await storage.child(imageUrl).getDownloadURL();

          const updatedTransactionList = [...transactionList];
          updatedTransactionList[index].transactionUrl = imgUrl;
          setTransactionList(updatedTransactionList);
          setSelectedTrans({});
          return window.open(imgUrl);
        }
      }
    } catch (err) {
      setSelectedTrans({});
      alert(err);
    }
  };

  const handleDialogButtons = async (type) => {
    let url = "";
    let initialUrl = "";
    try {
      if (type === 1) {
        url = selectedTrans?.item?.transactionImage;
        initialUrl = selectedTrans?.item?.transactionUrl ?? false;
      } else {
        url = selectedTrans?.item?.transactionImageSecondary;
        initialUrl = selectedTrans?.item?.transactionImageSecondaryUrl ?? false;
      }

      if (initialUrl) return window.open(initialUrl);

      setOpenImageDialogLoading(true);
      const imgUrl = await storage.child(url).getDownloadURL();
      let tList = transactionList;

      if (type === 1) tList[selectedTrans.index].transactionUrl = imgUrl;
      if (type === 2)
        tList[selectedTrans.index].transactionImageSecondaryUrl = imgUrl;

      setOpenImageDialogLoading(false);
      setTransactionList(tList);
      return window.open(imgUrl);
    } catch (err) {
      setOpenImageDialogLoading(false);
      alert(err);
    }
  };

  const setPayValidity = async (status, transId) => {
    try {
      const index = transactionList.findIndex((x) => x.transId === transId);
      const id = transactionList[index].transactionId;

      let obj = {
        status,
        id,
      };

      const { data } = await functions.doChangePaymentValidity(obj);
      if (data.ok) {
        let tList = transactionList;
        tList[index].paymentValid = status;
        setTransactionList(tList);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  const imageModal = (
    <Dialog
      onClose={() => setImageDialog(false)}
      aria-labelledby="customized-dialog-title"
      open={imageDialog}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setImageDialog(false)}
      >
        Secondary Deposit Slip Image Available
      </DialogTitle>
      <DialogContent dividers>
        <span>Client has uploaded a secondary deposit slip:</span>
        <br />
        <br />
        <Button
          color="primary"
          size="small"
          variant="contained"
          fullWidth
          disabled={openImageDialogLoading}
          style={{ color: "#ffffff", fontFamily: "Poppins" }}
          onClick={() => handleDialogButtons(1)}
        >
          View Primary Image
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          fullWidth
          disabled={openImageDialogLoading}
          style={{ color: "#ffffff", fontFamily: "Poppins", margin: "5px 0px" }}
          onClick={() => handleDialogButtons(2)}
        >
          View Secondary Image
        </Button>
        {openImageDialogLoading ? <LinearProgress /> : ""}
      </DialogContent>
    </Dialog>
  );

  const loader = (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 999,
      }}
    >
      <CircularProgress />
    </div>
  );

  const transactionListupdated = transactionList.filter((item) =>
    props.isOneOff
      ? ["one-time", "dividend-reinvestment"].includes(item.transactionType)
      : item.transactionType === "recurring"
  );

  const datePicker = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={
          transactionListLoading
            ? { display: "none" }
            : {
              display: "flex",
              alignItems: "center",
              margin: "-40px 0px 0px -1400px",
            }
        }
      >
        <strong
          style={{
            fontSize: "18px",
            margin: "0 10px",
            padding: "10px 0",
          }}
        >
          Start
        </strong>
        <TextField
          type="date"
          value={sortdate.startDate}
          onChange={(e) =>
            setSortDate({ ...sortdate, startDate: e.target.value })
          }
          InputProps={{
            style: {
              border: "1px solid #000",
              padding: "5px 10px",
            },
          }}
          error={
            sortdate.startDate !== "" &&
            sortdate.toDate !== "" &&
            sortdate.startDate > sortdate.toDate
          }
          helperText={
            sortdate.startDate !== "" &&
            sortdate.toDate !== "" &&
            sortdate.startDate > sortdate.toDate &&
            "Invalid Format"
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong
            style={{
              fontSize: "18px",
              margin: "0 10px",
              padding: "10px 0",
            }}
          >
            End
          </strong>
          <TextField
            type="date"
            value={sortdate.toDateDate}
            onChange={(e) =>
              setSortDate({ ...sortdate, toDate: e.target.value })
            }
            InputProps={{
              style: {
                border: "1px solid #000",
                padding: "5px 10px",
              },
            }}
            error={
              (sortdate.startDate !== "" && sortdate.toDate === "") ||
              sortdate.startDate > sortdate.toDate
            }
            helperText={
              sortdate.startDate !== "" &&
              sortdate.toDate !== "" &&
              sortdate.startDate > sortdate.toDate &&
              "Invalid Format"
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );

  const getDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const startDate = getDate(sortdate.startDate);
  const endDate = getDate(sortdate.toDate);

  const sortByDate = transactionListupdated.filter((item) => {
    const itemDate = getDate(item.subDate);
    return itemDate >= startDate && itemDate <= endDate;
  });

  return (
    <div className="cms-main-content">
      <div className="body-content">
        {imageModal}
        <div className="transaction-list">
          <h1>{`${props.isOneOff ? "One-off" : "Recurring"} Transactions`}</h1>
          <span>{`Listing of ${props.isOneOff ? "one-off" : "recurring"
            } transactions`}</span>
          <br /> <br /> <br />
          {props.isOneOff && (
            <CustomTabs
              value={tab}
              onChange={(e, newValue) => {
                setParameter([]);
                setTab(newValue);
              }}
              indicatorColor="primary"
            >
              <CustomTab label="Booking" value={"in"} key="0" />
              <CustomTab label="Redemption" value={"out"} key="1" />
            </CustomTabs>
          )}
          <Grid
            container
            spacing={1}
            style={{ marginTop: "10px", flexDirection: "row-reverse" }}
          >
            <Grid item xs={5} style={{ marginLeft: "-66px" }}>
              <DownloadComponents
                transactionList={
                  sortdate.startDate !== "" && sortdate.toDate !== ""
                    ? sortByDate
                    : transactionListupdated
                }
                currentTab={tab === "in" ? "booking" : "redemption"}
              />
            </Grid>
            {datePicker}
            <Grid item />
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ position: "relative" }}
            >
              {transactionListLoading && loader}
              <CustomDataTable
                title={`${props.isOneOff ? "One-off" : "Recurring"
                  } Transactions List`}
                data={
                  sortdate.startDate !== "" && sortdate.toDate !== ""
                    ? sortByDate
                    : transactionListupdated
                }
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const OneOffTransactionPage = () => {
  return <Navigation content={<TransactionList isOneOff={true} />} />;
};

const RecurringTransactionPage = () => {
  return <Navigation content={<TransactionList isOneOff={false} />} />;
};

const TransactionList = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(List);

export { OneOffTransactionPage, RecurringTransactionPage };

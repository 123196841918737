import { useEffect, useState } from 'react';
import axios from 'axios';
import firebase from "firebase/compat/app";
import moment from 'moment';

import * as ROUTES from "../../constants/routes";
import "firebase/compat/firestore";

export const useUploadRFO = () => {
    const [uploading, setUploading] = useState(false);
    const [response, setResponse] = useState('');
    const [errorDetails, setErrorDetails] = useState('');

    const uploadFile = async (file) => {
        if (!file) {
            throw new Error('No file provided for upload.');
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setUploading(true);
            const response = await axios.post(ROUTES.FUNCTIONAL_BASE_URL + 'funds-dividend-dividend/parse-rfo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // const contentDisposition = response.headers['Content-Disposition'];
            const filename = `${moment().format("YYYY-MM-DD HH:mm:ss")} - RFO-Converted.xlsx`;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            link.remove();
            URL.revokeObjectURL(link.href);
            setResponse({
                message: 'RFO file has been successfully converted.',
            })
        } catch (error) {
            if (error.response) {
                const contentType = error.response.headers['content-type'];
                if (contentType && contentType.includes('application/json')) {
                    const errorData = JSON.parse(await error.response.data.text());
                    setErrorDetails(errorData);
                } else {
                    const blob = error.response.data;
                    const text = await blob.text(); // Convert Blob to text
                    const errorData = JSON.parse(text);
                    setErrorDetails(errorData);
                }
            } else {
                setErrorDetails({
                    message: 'An unexpected error occurred during the upload.',
                });
            }
        } finally {
            setUploading(false);
        }
    };

    return { uploadFile, uploading, response, setResponse, errorDetails, setErrorDetails };
};

export const useUploadTransaction = () => {
    const [uploading, setUploading] = useState(false);
    const [response, setResponse] = useState('');
    const [errorDetails, setErrorDetails] = useState('');

    const uploadFile = async (file) => {
        if (!file) {
            throw new Error('No file provided for upload.');
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setUploading(true);
            const response = await axios.post(ROUTES.FUNCTIONAL_BASE_URL + 'funds-dividend-dividend/parse-dividend', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setResponse(response.data); // Set response data for the modal
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorDetails(error.response.data);
            } else {
                setErrorDetails({
                    error: 'Unexpected Error',
                    message: 'An unexpected error occurred during the upload.',
                });
            }
        } finally {
            setUploading(false);
        }
    };

    return { uploadFile, uploading, response, setResponse, errorDetails, setErrorDetails };
};
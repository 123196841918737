import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withRouter } from "react-router-dom";
import { FirebaseContext, withFirebase } from "../Firebase";
import Navigation from "../Navigation";
import { Box, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Backdrop, CircularProgress } from "@material-ui/core";
import CustomDataTable from "../CustomDataTable";
import { useState } from "react";
import { useUploadRFO, useUploadTransaction } from "./hooks";

import RFOFormat from "../../images/rfo-format.png"
import { DropzoneAreaBase } from "material-ui-dropzone";

const FundReinvestment = (props) => {
    const [selectedRFOFile, setSelectedRFOFile] = useState(null);
    const [selectedTrFile, setSelectedTrFile] = useState(null);

    const { uploadFile: uploadFileRFO, uploading: uploadingRFO, response: rfoRes, setResponse: setRFORes, errorDetails: rfoErrRes, setErrorDetails: setRFOErrRes } = useUploadRFO();
    const { uploadFile: uploadFileTr, uploading: uploadingTr, response: transactionRes, setResponse: setTransactionRes, errorDetails: transactionErrRes, setErrorDetails: setTransactionErrRes } = useUploadTransaction();


    const handleRFOFile = (file) => setSelectedRFOFile(file[0]);

    const handleTransactionFile = (file) => setSelectedTrFile(file[0]);

    const handleConfirmUpload = async (type) => {
        try {
            if (type === 'rfo') {
                await uploadFileRFO(selectedRFOFile.file);
                setSelectedRFOFile(null);
            }
            else if (type === 'tr') {
                await uploadFileTr(selectedTrFile.file)
                setSelectedTrFile(null);
            }
        } catch (error) {
            alert('Failed to upload the file');
        }
    };

    const handleCloseTRResModal = () => {
        setTransactionRes('');
    };

    const handleCloseTRErrorModal = () => {
        setTransactionErrRes('');
    };

    const handleCloseRFOResModal = () => {
        setRFORes('');
    };

    const handleCloseRFOErrorModal = () => {
        setRFOErrRes('');
    };

    return (
        <div className={"dashboardRoot"}>
            <div className={"dashboardMasterContainer"}>
                {/* Step 1 Modal */}
                <Dialog
                    open={!!rfoRes}
                    onClose={handleCloseRFOResModal}
                    aria-labelledby="response-dialog-title"
                >
                    <DialogTitle id="response-dialog-title">RFO</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {rfoRes.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRFOResModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Step 1 Error Modal */}
                <Dialog
                    open={!!rfoErrRes}
                    onClose={handleCloseRFOErrorModal}
                    aria-labelledby="error-dialog-title"
                >
                    <DialogTitle id="error-dialog-title">RFO Upload Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <strong>{rfoErrRes?.message || 'N/A'}</strong>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRFOErrorModal} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


                {/* Step 2 Modal */}
                <Dialog
                    open={!!transactionRes}
                    onClose={handleCloseTRResModal}
                    aria-labelledby="response-dialog-title"
                >
                    <DialogTitle id="response-dialog-title">Upload Response</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {transactionRes.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTRResModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Step 2 Error Modal */}
                <Dialog
                    open={!!transactionErrRes}
                    onClose={handleCloseTRErrorModal}
                    aria-labelledby="error-dialog-title"
                >
                    <DialogTitle id="error-dialog-title">Upload Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <strong>{transactionErrRes?.message || 'N/A'}</strong>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTRErrorModal} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>Fund Reinvestment</h1>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>Upload RFO File Format</h3>
                </Box>

                <Box sx={{ display: 'flex', gap: '240px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <span>
                            This section is for converting the RFO File Format to the transaction format.
                        </span>
                        <span>
                            Please follow the following format, the excel file MUST only contain the following columns:
                            <br />
                            <b>[Account Number], [Fund Code]</b>
                        </span>
                        <span>
                            Please check your downloads folder for the generated file.
                        </span>
                    </Box>
                    <img
                        src={RFOFormat}
                        alt="RFO Conversion"
                        style={{ width: '300px', height: 'auto' }}
                    />
                </Box>
                <Box sx={{ padding: '30px', position: 'relative' }}>
                    {uploadingRFO && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                zIndex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '4px',
                            }}
                        >
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                    <DropzoneAreaBase
                        acceptedFiles={[
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "xls",
                        ]}
                        filesLimit={1}
                        showPreviewsInDropzone={true}
                        onAdd={handleRFOFile}
                    />
                    <span
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        {selectedRFOFile ? selectedRFOFile.file.name : ''}
                    </span>

                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                        <Button
                            variant="contained"
                            className={"approve-button"}
                            disabled={selectedRFOFile === null}
                            onClick={() => handleConfirmUpload('rfo')}
                        >
                            Submit RFO
                        </Button>
                    </Box>

                </Box>

                <Divider variant="middle" />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>Upload Transaction file format</h3>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', position: 'relative' }}>
                    {uploadingTr && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                zIndex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '4px',
                            }}
                        >
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                    <span>
                        This section is for <b>CREATING</b> dividends using the transaction format generated from the RFO file
                    </span>
                    <ul>
                        <li><b>[Shares], [NAVPS Booking Time]</b></li>
                        <li>Set <b>[Status]</b> as 'reinvested'</li>
                        <li><b>[Date Processed]</b> formatted as YYYY-MM-DD</li>
                        <li><b>[Outstanding Shares]</b> based on partner report</li>
                    </ul>
                    <span>
                        After uploading, it will download the same template with the transaction ID for reference.
                        <br />
                        Reuploading would overwrite the current data.
                    </span>
                    <Box sx={{ padding: '30px' }}>
                        <DropzoneAreaBase
                            acceptedFiles={[
                                "application/vnd.ms-excel",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "xls",
                            ]}
                            filesLimit={1}
                            showPreviewsInDropzone={true}
                            onAdd={handleTransactionFile}
                        />
                        <span style={{ fontSize: "14px", fontWeight: "600", paddingTop: "10px", paddingBottom: "10px" }}>
                            {selectedTrFile ? selectedTrFile.file.name : ''}
                        </span>

                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                className={"approve-button"}
                                disabled={selectedTrFile === null}
                                onClick={() => handleConfirmUpload('tr')}
                            >
                                Submit Transaction
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

const condition = (authUser) => !!authUser;

const FundReinvestmentPage = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(() =>
    <FirebaseContext.Consumer>
        {(firebase) => (
            <Navigation
                content={
                    <FundReinvestment
                        firebase={firebase}
                    />
                }
            />
        )}

    </FirebaseContext.Consumer>
)

export default FundReinvestmentPage;
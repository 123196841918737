export const SIGN_IN = "/";
export const USERS = "/client-list";
export const REFERRERS = "/referrers";
export const ACCESS_CONTROL = "/access-control";
export const INVESTMENT_REQUEST = "/investment-request";
export const CONTENT = "/manage-content/terms-and-condition";
export const ADD_USER = "/add-user";
export const EDIT_USER = "/edit-user";
export const CLIENT_PORFILE = "/client-profile";
export const TRANSACTION_LIST = "/operations/transaction-list";
export const RECURRING_TRANSACTIONS = "/operations/recurring";
export const INVESTMENT_DETAILS = "/operations/investment-details";
export const INVESTMENT_REQUEST_UPLOAD = "/upload-booking";
export const ACCOUNT = "/account";
export const PRIVACY_POLICY = "/manage-content/privacy-policy";
export const REDEMPTION_TERMS = "/manage-content/redemption-terms";
export const CATEGORIES_LIST = "/manage-content/category-list";
export const CATEGORIES_ADD = "/manage-content/category-add";
export const CATEGORIES_EDIT = "/manage-content/catergory-edit";
export const FUND_LIST = "/manage-content/funds";
export const FUND_ADD = "/manage-content/fund/add";
export const FUND_EDIT = "/manage-content/fund/edit";
export const FUND_RATES_UPLOAD = "/manage-content/fund-rates-upload";
export const COMPANY_LIST = "/manage-content/companies";
export const COMPANY_ADD = "/manage-content/company/add";
export const COMPANY_EDIT = "/manage-content/company/edit";
export const FEEDBACK = "/feedback";
export const ANALYTICS_DASHBOARD = "/analytics-dashboard";
export const DASHBOARD = "/dashboard";
export const WITHDRAWAL_REQUEST_UPLOAD = "/upload-withdrawal";
export const USERS_COACH = "/userscoach";
export const RECENT_UPLOAD = "/recentuploads";
export const RECURRING_INVESTMENTS = "/recurring-investments";
export const NAVPS_EDIT = "/manage-content/navps";
export const INVALID = "/invalid";
export const GRADUATING_USERS = "/graduating-users"
export const TRC = "/TRC"
export const FUND_REINVESTMENT = "/fund-reinvestment"

export var FUNCTIONAL_BASE_URL;
if (process.env.REACT_APP_FIREBASE_CONFIG === "live") {
  FUNCTIONAL_BASE_URL = "https://asia-southeast2-rampver.cloudfunctions.net/";
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "nonprod") {
  FUNCTIONAL_BASE_URL = "https://asia-east2-dip-nonprod.cloudfunctions.net/";
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "staging") {
  FUNCTIONAL_BASE_URL =
    "https://asia-southeast1-dip-staging.cloudfunctions.net/";
} else {
  FUNCTIONAL_BASE_URL = "https://asia-east2-dip-dev-882b0.cloudfunctions.net/";
}

// base url of the client app
export let CLIENT_BASE_URL = "";

switch (process.env.REACT_APP_FIREBASE_CONFIG) {
  case "nonprod":
    CLIENT_BASE_URL = "https://dip-nonprod.web.app";
    break;
  case "live":
    CLIENT_BASE_URL = "https://app.rampver.com";
    break;
  case "staging":
    CLIENT_BASE_URL = "https://dip-staging.web.app";
    break;
  default:
    CLIENT_BASE_URL = "https://dip-dev.web.app";
    break;
}

import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Fade } from "@material-ui/core";

import SignInPageRoute from "../SignIn";
import UsersPageRoute from "../Users/UserList";
import AccessControlPageRoute from "../AccessControl";
import InvestmentRequestPageRoute from "../InvestmentRequest";
import ContentPageRoute from "../Content/Categories/CategoriesList";
import AddUserPageRoute from "../Users/UserAdd";
import EditUserPageRoute from "../Users/UserEdit";
import ClientProfilePageRoute from "../Users/ClientProfile";
import {
  OneOffTransactionPage,
  RecurringTransactionPage,
} from "../Operations/TransactionList";
import NewInvestments from "../Operations/NewInvestmentsList/NewInvestmentList";
import InvestmentRequestUpload from "../Operations/requestUpload";
import InvestmentDetails from "../Operations/InvestmentDetails";
import AccountPageRoute from "../Account";
import TermsPageRoute from "../Content/TermsAndCondition";
import CategoriesAddPageRoute from "../Content/Categories/CategoriesAdd";
import CategoriesEditPageRoute from "../Content/Categories/CategoriesEdit";
import FundListPageRoute from "../Content/Funds/FundList";
import FundAddEditPageRoute from "../Content/Funds/FundAddEdit";
import FundRatesUploadPageRoute from "../Content/Funds/FundRatesUpload";
import CompanyListPageRoute from "../Content/Company/CompanyList";
import CompanyAddEditPageRoute from "../Content/Company/CompanyAddEdit";
import DashboardPageRoute from "../Dashboard";
import InvalidToken from "../GenericPages/InvalidToken";
import RecentUpload from "../RecentlyUploaded";
import FeedbackPage from "../Feedback";
import * as ROUTES from "../../constants/routes";
import ReferrersPage from "../ReferrersPage";
import ReferrerProfile from "../ReferrerProfile";
import RecurringInvestment from "../Operations/RecurringInvestments";

import { withAuthentication } from "../Session";
import NavpsPage from "../Navps";
import GraduatingUsersListPage from "../Users/Graduating";
import TRCBatchDisplay from "../TRC";
import AnalyticsDashboard from "../DashboardV2/Analytics/AnalyticsDashboard";
import ActionsDashboard from "../DashboardV2/Action/ActionsDashboard";
import FundReinvestmentPage from "../Reinvestment";

const App = (props) => {
  return (
    <Fade in={true}>
      <div>
        <Router>
          <Switch>
            <Route exact path={ROUTES.SIGN_IN} component={SignInPageRoute} />
            <Route
              exact
              path={ROUTES.ANALYTICS_DASHBOARD}
              component={AnalyticsDashboard}
            />
            <Route
              exact
              path={ROUTES.DASHBOARD}
              component={ActionsDashboard}
            />
            <Route exact path={ROUTES.USERS} component={UsersPageRoute} />
            <Route
              exact
              path={ROUTES.ACCESS_CONTROL}
              component={AccessControlPageRoute}
            />
            <Route
              exact
              path={ROUTES.INVESTMENT_REQUEST}
              component={InvestmentRequestPageRoute}
            />
            <Route
              exact
              path={ROUTES.CATEGORIES_LIST}
              component={ContentPageRoute}
            />
            <Route exact path={ROUTES.ADD_USER} component={AddUserPageRoute} />
            <Route
              exact
              path={ROUTES.EDIT_USER}
              component={EditUserPageRoute}
            />
            <Route
              exact
              path={ROUTES.CLIENT_PORFILE}
              component={ClientProfilePageRoute}
            />
            <Route
              exact
              path={ROUTES.TRANSACTION_LIST}
              component={OneOffTransactionPage}
            />
            <Route
              exact
              path={ROUTES.RECURRING_TRANSACTIONS}
              component={RecurringTransactionPage}
            />
            <Route
              exact
              path={ROUTES.INVESTMENT_REQUEST_UPLOAD}
              component={InvestmentRequestUpload}
            />
            <Route
              exact
              path={`${ROUTES.INVESTMENT_DETAILS}/:code`}
              component={InvestmentDetails}
            />
            <Route exact path={ROUTES.ACCOUNT} component={AccountPageRoute} />
            <Route exact path={ROUTES.CONTENT} component={TermsPageRoute} />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={TermsPageRoute}
            />
            <Route
              exact
              path={ROUTES.REDEMPTION_TERMS}
              component={TermsPageRoute}
            />
            <Route
              exact
              path={ROUTES.CATEGORIES_ADD}
              component={CategoriesAddPageRoute}
            />
            <Route
              exact
              path={ROUTES.CATEGORIES_EDIT}
              component={CategoriesEditPageRoute}
            />
            <Route
              exact
              path={ROUTES.FUND_LIST}
              component={FundListPageRoute}
            />
            <Route
              exact
              path={ROUTES.FUND_ADD}
              component={FundAddEditPageRoute}
            />
            <Route
              exact
              path={ROUTES.FUND_EDIT}
              component={FundAddEditPageRoute}
            />
            <Route
              exact
              path={ROUTES.FUND_RATES_UPLOAD}
              component={FundRatesUploadPageRoute}
            />
            <Route
              exact
              path={ROUTES.COMPANY_LIST}
              component={CompanyListPageRoute}
            />
            <Route
              exact
              path={ROUTES.COMPANY_ADD}
              component={CompanyAddEditPageRoute}
            />
            <Route
              exact
              path={ROUTES.COMPANY_EDIT}
              component={CompanyAddEditPageRoute}
            />
            <Route
              exact
              path={ROUTES.WITHDRAWAL_REQUEST_UPLOAD}
              component={InvestmentRequestUpload}
            />
            <Route
              exact
              path={ROUTES.RECURRING_INVESTMENTS}
              component={RecurringInvestment}
            />
            <Route
              exact
              path={ROUTES.GRADUATING_USERS}
              component={GraduatingUsersListPage}
            />
            <Route exact path={ROUTES.NAVPS_EDIT} component={NavpsPage} />
            <Route exact path={ROUTES.RECENT_UPLOAD} component={RecentUpload} />
            <Route exact path={ROUTES.FEEDBACK} component={FeedbackPage} />
            <Route exact path={ROUTES.INVALID} component={InvalidToken} />
            <Route exact path={ROUTES.REFERRERS} component={ReferrersPage} />
            <Route
              exact
              path="/referrers/profiles/:id"
              component={ReferrerProfile}
            />
             <Route
              exact
              path={ROUTES.TRC}
              component={TRCBatchDisplay}
            />
            <Route
              exact
              path={ROUTES.FUND_REINVESTMENT}
              component={FundReinvestmentPage}
            />
          </Switch>
        </Router>
      </div>
    </Fade>
  );
};

export default withAuthentication(App);
